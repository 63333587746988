.colors-top-bg {
  background: #d67328;
  background: -moz-linear-gradient(top, #d67328 0%, #c49d31 100%);
  background: -webkit-linear-gradient(top, #d67328 0%, #c49d31 100%);
  background: linear-gradient(to bottom, #d67328 0%, #c49d31 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d67328', endColorstr='#c49d31', GradientType=0);
}
.colors-board-bg {
  background: #8e3e19;
  background: -moz-linear-gradient(top, #8e3e19 0%, #9a480e 100%);
  background: -webkit-linear-gradient(top, #8e3e19 0%, #9a480e 100%);
  background: linear-gradient(to bottom, #8e3e19 0%, #9a480e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8e3e19', endColorstr='#9a480e', GradientType=0);
}
.colors-box-bg {
  background: #70f561;
  background: radial-gradient(circle, #70f561 0%, #207909 99%);
}
.colors-primary-btn-grad {
  background: #f59261;
  background: linear-gradient(180deg, #f59261 0%, #c7781c 99%);
}
.colors-secondary-btn-grad {
  background: #2e91f4;
  background: -moz-linear-gradient(top, #2e91f4 0%, #0571b9 100%);
  background: -webkit-linear-gradient(top, #2e91f4 0%, #0571b9 100%);
  background: linear-gradient(to bottom, #2e91f4 0%, #0571b9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e91f4', endColorstr='#0571b9', GradientType=0);
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url('./res/fonts/NotoSansJP-Regular.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 700;
  src: url('./res/fonts/NotoSansJP-Bold.otf');
}
body {
  background: #d67328;
  background: -moz-linear-gradient(top, #d67328 0%, #c49d31 100%);
  background: -webkit-linear-gradient(top, #d67328 0%, #c49d31 100%);
  background: linear-gradient(to bottom, #d67328 0%, #c49d31 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d67328', endColorstr='#c49d31', GradientType=0);
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}
.top-bar {
  display: flex;
  background: #8e3e19;
  background: -moz-linear-gradient(top, #8e3e19 44%, #9a480e 63%);
  background: -webkit-linear-gradient(top, #8e3e19 44%, #9a480e 63%);
  background: linear-gradient(to bottom, #8e3e19 44%, #9a480e 63%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8e3e19', endColorstr='#9a480e', GradientType=0);
  height: 52px;
  display: grid;
  grid-template-columns: 50px auto 50px;
  max-width: 100%;
}
.top-bar .button {
  color: #fff;
  text-align: center;
  vertical-align: middle;
  line-height: 48px;
  font-size: 22px;
  border-right: 1px solid #662e06;
}
.top-bar .button:last-child {
  border-right: none;
}
.top-bar .button:active {
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
}
.dropdown-menu {
  background: #dae0e5;
  z-index: 2500;
}
.dropdown-menu .dropdown-item {
  padding: 0.5rem 1.75rem;
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: rgba(100, 100, 100, 0.1);
}
.dropdown-menu .dropdown-item:active {
  background: #2e91f4;
  background: -moz-linear-gradient(top, #2e91f4 0%, #0571b9 100%);
  background: -webkit-linear-gradient(top, #2e91f4 0%, #0571b9 100%);
  background: linear-gradient(to bottom, #2e91f4 0%, #0571b9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e91f4', endColorstr='#0571b9', GradientType=0);
}
.seated-players {
  display: flex;
  overflow: auto;
}
.seated-players .player {
  height: 100%;
  text-align: center;
  flex-basis: auto;
  flex: 1;
  vertical-align: middle;
  border-right: 1px solid #662e06;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 100px;
}
.seated-players .player .name {
  display: inline-block;
  color: #ddd;
  font-weight: bold;
  font-size: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
.seated-players .player .info {
  color: gold;
  font-weight: bold;
  font-size: 15px;
}
.seated-players .player.self .name {
  color: #fff;
}
.seated-players .player.self .info {
  text-decoration: underline;
}
.seated-players .player.active {
  background: -moz-linear-gradient(top, rgba(216, 109, 15, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(216, 109, 15, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(216, 109, 15, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6d86d0f', endColorstr='#00000000', GradientType=0);
}
.show-deck {
  background: #8e3e19;
  background: -moz-linear-gradient(top, #8e3e19 0%, #9a480e 100%);
  background: -webkit-linear-gradient(top, #8e3e19 0%, #9a480e 100%);
  background: linear-gradient(to bottom, #8e3e19 0%, #9a480e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8e3e19', endColorstr='#9a480e', GradientType=0);
  padding: 10px 5px;
  max-width: 100%;
  overflow: auto;
}
.show-deck .playing-deck {
  background: none;
}
.playing-deck {
  background: #8e3e19;
  background: -moz-linear-gradient(top, #8e3e19 0%, #9a480e 100%);
  background: -webkit-linear-gradient(top, #8e3e19 0%, #9a480e 100%);
  background: linear-gradient(to bottom, #8e3e19 0%, #9a480e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8e3e19', endColorstr='#9a480e', GradientType=0);
  padding: 10px 5px;
}
.playing-deck .deck-holder-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.playing-deck .title {
  margin-bottom: 5px;
  text-align: center;
}
.playing-deck .title .name {
  font-size: 25px;
  color: #662e06;
  background: #ffd65e;
  background: -moz-linear-gradient(top, #ffd65e 0%, #febf04 100%);
  background: -webkit-linear-gradient(top, #ffd65e 0%, #febf04 100%);
  background: linear-gradient(to bottom, #ffd65e 0%, #febf04 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd65e', endColorstr='#febf04', GradientType=0);
  font-weight: bold;
  padding: 0 10px;
  border-radius: 10px;
}
.playing-deck .deck-holder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.playing-deck .deck-holder .list-holder {
  overflow-x: auto;
  display: flex;
}
.playing-deck .deck-holder .list-holder .card-list:not(:first-child) {
  margin-left: 25px;
}
.playing-deck .deck-holder-container {
  display: grid;
  grid-template-columns: auto auto;
  position: relative;
  padding: 3px 0;
}
.playing-deck .deck-holder-container .list-holder {
  max-width: 100%;
  overflow: auto;
}
.playing-deck .deck-holder-container .list-holder .card-list {
  overflow-y: hidden;
}
.playing-deck .deck-holder-container .list-holder.prev-cards .card-list {
  max-width: 300px;
}
.playing-deck .deck-holder-container .list-holder.the-deck {
  display: flex;
}
.playing-deck .deck-holder-container .list-holder.the-deck .card.deck-card {
  z-index: 2;
}
.playing-deck .deck-holder-container .list-holder.the-deck .card.deck-joker {
  transform: rotate(90deg);
  transform-origin: 0% 0%;
  z-index: 1;
  height: 60px;
  position: relative;
  left: 60px;
  top: 25px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-position: right top;
}
.playing-deck .deck-holder-container .list-holder.the-deck .card.deck-joker .display.bottom {
  display: none;
}
.playing-deck.flash .card.closed {
  animation-name: flash;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.playing-deck.flash .card-list .holder:first-child .card {
  animation-name: flash;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.playing-deck .holder.sortable-ghost {
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
}
.playing-deck .holder.sortable-ghost .card {
  display: none;
}
.card {
  width: 160px;
  height: 240px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transition: box-shadow 300ms, margin-top 200ms;
  -moz-transition: box-shadow 300ms, margin-top 200ms;
  -o-transition: box-shadow 300ms, margin-top 200ms;
  transition: box-shadow 300ms, margin-top 200ms;
  display: block;
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}
.card.closed {
  background: lightblue;
  background-image: url('./res/close-card.png');
  background-size: 100% 100%;
}
.card:active {
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.8), 0px 0px 10px 0px #fff;
}
.card .display {
  font-size: 35px;
  position: absolute;
  line-height: 1;
  font-weight: bold;
  background-color: inherit;
}
.card .display.top {
  left: 5px;
  top: 8px;
  transform: scale(1.2);
}
.card .display.bottom {
  transform: rotate(180deg);
  right: 5px;
  bottom: 5px;
}
.card .display:after {
  display: block;
  font-size: 70%;
}
.card.joker,
.card.cut-joker {
  background-image: url("./res/joker.png");
  background-size: 70% auto;
  background-repeat: no-repeat;
  background-position: center center;
}
.card.joker.joker .display:after,
.card.cut-joker.joker .display:after {
  content: "J O K E R";
  font-size: 60%;
  max-width: 0px;
}
.card.joker.joker .display.top,
.card.cut-joker.joker .display.top {
  color: #1e7e34;
  margin-top: 5px;
}
.card.joker.joker .display.bottom,
.card.cut-joker.joker .display.bottom {
  color: #17a2b8;
}
.card.hearts .display {
  color: red;
}
.card.hearts .display:after {
  content: "\2665";
}
.card.spades .display {
  color: black;
}
.card.spades .display:after {
  content: "\2660";
}
.card.clubs .display {
  color: black;
}
.card.clubs .display:after {
  content: "\2663";
}
.card.dimonds .display {
  color: red;
}
.card.dimonds .display:after {
  content: "\2666";
}
.card-list {
  display: flex;
}
.card-list .holder.half {
  width: 50px;
}
.card-list .holder.half .card .display.bottom {
  display: none;
}
.card-list .holder.sortable-drag {
  opacity: 1 !important;
}
.card-list .holder.sortable-drag .card {
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.8), 0px 0px 10px 0px #fff;
}
.card-list .holder.sortable-drag .card .display.bottom {
  display: block;
}
.card-list .holder.sortable-ghost .card {
  opacity: 1;
  background-color: lightblue;
}
.card-list .holder.selected .card {
  background-color: #fffdc3;
  margin-top: 0px;
}
.card-list.reverse {
  overflow: auto;
  direction: rtl;
}
.card-list.reverse .holder .half {
  float: left;
}
.card-list.reverse .holder .joker {
  direction: ltr;
}
.statusbox {
  padding: 10px;
  background: #339624;
  background: -moz-linear-gradient(top, #339624 0%, #1e7e34 100%);
  background: -webkit-linear-gradient(top, #339624 0%, #1e7e34 100%);
  background: linear-gradient(to bottom, #339624 0%, #1e7e34 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#339624', endColorstr='#1e7e34', GradientType=0);
  color: black;
  font-size: 18px;
  text-align: center;
  z-index: 1000;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.statusbox .msg {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 5px;
}
.statusbox .buttons .btn {
  margin-right: 5px;
  font-weight: bold;
}
.btn {
  min-width: 100px;
  white-space: nowrap;
}
.btn-inair {
  background: #17a2b8;
  padding: 5px 10px;
  color: #fff;
  font-weight: bold;
}
.btn-inair:focus,
.btn-inair:visited,
.btn-inair:active,
.btn-inair:hover {
  color: #fff;
}
.btn-inair:active {
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  background: #148ea1;
}
.player-hand {
  background: #339624;
  background: -moz-linear-gradient(top, #339624 0%, #60dc4f 100%);
  background: -webkit-linear-gradient(top, #339624 0%, #60dc4f 100%);
  background: linear-gradient(to bottom, #339624 0%, #60dc4f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#339624', endColorstr='#60dc4f', GradientType=0);
  position: relative;
  padding: 5px 20px;
}
.player-hand .score {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1000;
  padding: 0px 5px;
  background: rgba(46, 145, 244, 0.7);
  color: #fff;
  font-weight: bold;
  margin: 10px;
}
.player-hand .hand-buttons {
  padding: 5px;
  position: fixed;
  bottom: 5px;
  left: 5px;
}
.player-hand .card {
  margin-top: 10px;
}
.player-hand .list.landscape {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.player-hand .list.landscape .card-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  overflow: hidden;
}
.player-hand .list.landscape .card-list .holder {
  width: 100%;
}
.player-hand .list.landscape .card-list .holder .card {
  border-radius: 0px;
}
.player-hand .list.landscape .card-list .holder .card.closed {
  border-right: 3px solid darkgreen;
  background: transparent;
  box-shadow: none;
  border: none;
  float: right;
  border-right: 5px solid #60dc4f;
  width: 0px;
}
.player-hand .list.landscape .card-list .holder:last-child .card.closed {
  border-right: none;
}
.player-hand .list.portrait .card-list {
  margin-right: 5px;
  display: flex;
  flex-wrap: wrap;
}
.player-hand .list.portrait .card-list .holder {
  width: 50px;
}
.pull-right {
  float: right;
}
.clickable:hover {
  cursor: pointer;
}
.hint {
  outline: red;
}
.modal-popup .btn-link {
  color: #666;
}
.main-page {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-page .box {
  margin: 10px;
  padding: 30px;
  border-radius: 5px;
  text-align: center;
}
.main-page .box .button {
  padding: 30px;
  color: white;
  width: 160px;
  margin: 10px;
  font-weight: bold;
  font-size: 25px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0px 0px 0px 4px white;
  transition: all 200ms;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
}
.main-page .box .button:active {
  box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.8), 0px 0px 20px 0px #fff;
}
.main-page .box .button.newroom {
  background: #17a2b8;
  border: 4px solid #117a8b;
}
.main-page .box .button.newroom:active {
  background-color: #1fc8e3;
}
.main-page .box .button.joinroom {
  background: #17b81c;
  border: 4px solid #118b15;
}
.main-page .box .button.joinroom:active {
  background-color: #1fe325;
}
.main-page .box .button.playstranger {
  background: #ff9800;
  border: 4px solid #cc7a00;
}
.main-page .box .button.playstranger:active {
  background-color: #ffad33;
}
.main-page .box .user {
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  text-align: center;
}
.main-page .box .user:active {
  color: black;
}
.main-page .box .quick-actions {
  color: #fff;
  font-weight: bold;
  display: block;
  margin-top: 5px;
  text-align: center;
}
.main-page .box .quick-actions .btn-link {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  position: fixed;
  min-width: 0;
  font-size: 25px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  vertical-align: middle;
}
.main-page .box .quick-actions .btn-link span {
  font-size: 14px;
  float: right;
  text-align: left;
  margin-left: 10px;
  line-height: 16px;
  margin-top: 2px;
}
.main-page .box .quick-actions .btn-link:active {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.4);
}
.main-page .box .quick-actions .btn-link.top-right {
  top: 0;
  right: 0;
}
.main-page .box .quick-actions .btn-link.bottom-left {
  bottom: 0;
  left: 0px;
}
.main-page .box .quick-actions .btn-link.bottom-right {
  bottom: 0;
  right: 0;
}
.main-page .box .quick-actions .btn-link.bottom-right2 {
  bottom: 0;
  right: 65px;
}
.main-page .box .title {
  font-size: 25px;
  margin-bottom: 20px;
}
.main-page .box label {
  display: block;
  color: white;
  font-weight: bold;
}
.btn-primary {
  background: #f59261;
  background: linear-gradient(180deg, #f59261 0%, #c7781c 99%);
  border: 1px solid brown;
}
.btn-primary:hover {
  border: 1px solid brown;
}
.btn-primary:active,
.btn-primary:focus {
  border: 1px solid #900909 !important;
}
.btn-primary:active {
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
  background: #c7781c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #c7781c 1%, #f59261 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #c7781c 1%, #f59261 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #c7781c 1%, #f59261 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c7781c', endColorstr='#f59261', GradientType=0);
  /* IE6-9 */
}
.btn-secondary {
  background: #2e91f4;
  background: -moz-linear-gradient(top, #2e91f4 0%, #0571b9 100%);
  background: -webkit-linear-gradient(top, #2e91f4 0%, #0571b9 100%);
  background: linear-gradient(to bottom, #2e91f4 0%, #0571b9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e91f4', endColorstr='#0571b9', GradientType=0);
  border: 1px solid #0342a1;
}
.btn-secondary:hover {
  border: 1px solid #0342a1;
}
.btn-secondary.active {
  background: #0342a1 !important;
}
.btn-secondary:active {
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0571b9+0,2e91f4+100 */
  background: #0571b9;
  /* Old browsers */
  background: -moz-linear-gradient(top, #0571b9 0%, #2e91f4 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #0571b9 0%, #2e91f4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #0571b9 0%, #2e91f4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0571b9', endColorstr='#2e91f4', GradientType=0);
  /* IE6-9 */
}
.flex {
  display: flex;
}
html,
body {
  height: 100%;
  overflow: auto;
}
#root {
  height: 100%;
}
.game-page {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.landscape {
  display: block;
}
.portrait {
  display: none;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
}
.loader {
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid gold;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.anim-card {
  display: none;
  position: absolute;
  left: 50%;
  z-index: 2000;
  margin-left: -50px;
  margin-top: -75px;
}
.btn.min-width {
  min-width: 130px;
}
.modal-backdrop {
  z-index: 1050;
}
.modal-header {
  background: rgba(100, 100, 100, 0.1);
}
.modal-header .close:active {
  background: rgba(0, 0, 0, 0.2);
}
.modal-header .modal-title {
  font-weight: bold;
}
.modal-footer {
  justify-content: left;
  background: rgba(0, 0, 0, 0.1);
}
.modal-content {
  box-shadow: 0px 0px 5px 0px #662e06;
  border-radius: 5px;
  background-color: #dae0e5;
}
.hide {
  display: none !important;
}
.turn-on-mic {
  position: absolute;
  background: #fff;
  color: #17a2b8;
  font-weight: bold;
  padding: 10px;
  font-size: 16px;
  max-width: 200px;
  right: 10px;
  z-index: 1040;
  top: 60px;
  animation-name: wobble;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.turn-on-mic:before {
  content: "";
  border-bottom: 15px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: -15px;
  right: 10px;
}
.info-box {
  padding: 10px;
  text-align: center;
  color: rgba(100, 100, 100, 0.8);
  border: 1px dashed rgba(100, 100, 100, 0.3);
  margin: 10px 0;
}
.info-box a {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
.info-box a:hover {
  text-decoration: underline;
}
.the-accordion .the-toggle {
  display: block;
  background: rgba(100, 100, 100, 0.1);
  width: 100%;
  border: none;
  padding: 10px;
  border: 1px solid rgba(100, 100, 100, 0.3);
  margin-top: -1px;
}
.the-accordion .the-toggle .the-icon {
  float: right;
  font-size: 16px;
  margin: 5px;
}
.the-accordion .the-info {
  padding: 10px;
  border: 1px solid rgba(100, 100, 100, 0.3);
  border-top: none;
}
@keyframes wobble {
  from {
    margin-top: 0;
  }
  to {
    margin-top: 10px;
  }
}
@keyframes flash {
  from {
    box-shadow: inset 0px 0px 0px 2px red;
    border-color: red;
  }
  to {
    box-shadow: inset 0px 0px 0px 2px blue;
    border-color: blue;
  }
}
@media (max-width: 1100px) and (min-width: 900px) {
  .card {
    width: 140px;
    height: 210px;
  }
  .card .display {
    font-size: 32px;
  }
  .player-hand .list.landscape .card-list {
    grid-template-columns: repeat(auto-fit, minmax(37px, 1fr));
  }
}
@media (max-width: 900px) and (min-width: 770px) {
  .card {
    width: 120px;
    height: 180px;
  }
  .card .display {
    font-size: 30px;
  }
  .player-hand .list.landscape .card-list {
    grid-template-columns: repeat(auto-fit, minmax(35px, 1fr));
  }
}
@media (max-width: 770px) and (min-width: 630px) {
  .card {
    width: 90px;
    height: 135px;
  }
  .card .display {
    font-size: 27px;
  }
  .player-hand .list.landscape .card-list {
    grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
  }
  .seated-players .player {
    min-width: 90px;
  }
  .seated-players .player .name {
    font-size: 17px;
  }
  .seated-players .player .info {
    font-size: 14px;
  }
}
@media (max-width: 630px) and (min-width: 550px) {
  .card {
    width: 80px;
    height: 120px;
  }
  .card .display {
    font-size: 23px;
  }
  .player-hand .list.landscape .card-list {
    grid-template-columns: repeat(auto-fit, minmax(28px, 1fr));
  }
  .seated-players .player {
    min-width: 80px;
  }
  .seated-players .player .name {
    font-size: 16px;
  }
  .seated-players .player .info {
    font-size: 13px;
  }
}
@media (max-width: 550px) {
  .card {
    width: 100px;
    height: 150px;
  }
  .card .display {
    font-size: 25px;
  }
  .player-hand .list.landscape .card-list {
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  }
  .player-hand {
    padding: 5px;
  }
  .player-hand .score {
    position: fixed;
  }
  .landscape {
    display: none;
  }
  .portrait {
    display: block;
  }
  .player-hand .card-list {
    overflow: hidden;
    width: 350px;
  }
  .seated-players .player {
    min-width: 70px;
  }
  .seated-players .player .name {
    font-size: 12px;
  }
  .seated-players .player .info {
    font-size: 11px;
  }
}
@media (max-width: 550px) and (min-height: 600px) {
  .player-hand .card {
    height: 90px;
  }
}
@media (max-width: 550px) and (min-height: 700px) {
  .player-hand .card {
    height: 100px;
  }
}
@media (max-width: 550px) and (min-height: 800px) {
  .player-hand .card {
    height: 110px;
  }
}
@media (max-width: 550px) and (min-height: 900px) {
  .player-hand .card {
    height: 120px;
  }
}
@media (max-width: 550px) and (max-height: 600px) {
  .player-hand .card {
    height: 80px;
  }
}
.hanging-buttons {
  position: absolute;
  top: 55px;
  z-index: 1000;
}
.hanging-buttons.right {
  right: 0;
}
.hanging-buttons .btn {
  color: #fff;
  display: block;
  background: rgba(142, 62, 25, 0.75);
  min-width: 50px;
  font-size: 20px;
  opacity: 0.75;
}
.hanging-buttons .btn:active {
  opacity: 0.5;
}
.hanging-buttons .btn.warn {
  background: rgba(221, 4, 4, 0.5);
}
.hanging-buttons .btn.danger {
  background: #dd0404;
}
@media (max-width: 360px) {
  .card {
    width: 90px;
    height: 135px;
  }
  .card .display {
    font-size: 24px;
  }
  .player-hand .list.landscape .card-list {
    grid-template-columns: repeat(auto-fit, minmax(29px, 1fr));
  }
  .player-hand .list.portrait .card-list .holder {
    width: 40px;
  }
  .player-hand .card-list {
    width: 280px;
  }
}
@media (min-width: 550px) and (max-height: 650px) {
  .playing-deck .card {
    width: 100px;
    height: 150px;
  }
  .playing-deck .card .display {
    font-size: 30px;
  }
  .playing-deck .deck-holder .list-holder.prev-cards .card-list {
    max-width: 250px;
  }
  .playing-deck .deck-holder .list-holder.the-deck .card.deck-joker {
    height: 75px;
    left: 75px;
  }
}
@media (min-width: 550px) and (min-height: 650px) {
  .playing-deck .card {
    width: 133.33333333px;
    height: 200px;
  }
  .playing-deck .card .display {
    font-size: 32px;
  }
  .playing-deck .deck-holder .list-holder.prev-cards .card-list {
    max-width: 333.33333333px;
  }
  .playing-deck .deck-holder .list-holder.the-deck .card.deck-joker {
    height: 81px;
    left: 81px;
  }
}
@media (min-width: 550px) and (min-height: 700px) {
  .playing-deck .card {
    width: 166.66666667px;
    height: 250px;
  }
  .playing-deck .card .display {
    font-size: 35px;
  }
  .playing-deck .deck-holder .list-holder.prev-cards .card-list {
    max-width: 416.66666667px;
  }
  .playing-deck .deck-holder .list-holder.the-deck .card.deck-joker {
    height: 90px;
    left: 90px;
  }
}
@media (max-height: 500px) {
  .game-page .player-hand {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .game-page .statusbox {
    padding: 5px;
  }
  .game-page .statusbox .msg {
    font-size: 20px;
  }
  .game-page .playing-deck {
    padding: 5px 5px;
  }
  .player-hand .card {
    height: 120px;
  }
  .playing-deck .card {
    width: 80px;
    height: 120px;
  }
  .playing-deck .card .display {
    font-size: 25px;
  }
  .playing-deck .deck-holder .list-holder.prev-cards .card-list {
    max-width: 200px;
  }
  .playing-deck .deck-holder .list-holder.the-deck .card.deck-joker {
    height: 60px;
    left: 60px;
  }
  .card.joker,
  .card.cut-joker {
    background-position: center 10px;
  }
}
@media (max-height: 400px) {
  .game-page .player-hand {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .game-page .statusbox {
    padding: 2px;
  }
  .game-page .statusbox .msg {
    font-size: 18px;
  }
  .game-page .playing-deck {
    padding: 2px 5px;
  }
  .player-hand .card {
    height: 110px;
  }
  .playing-deck .card {
    width: 73.33333333px;
    height: 110px;
  }
  .playing-deck .card .display {
    font-size: 23px;
  }
  .playing-deck .deck-holder .list-holder.prev-cards .card-list {
    max-width: 183.33333333px;
  }
  .playing-deck .deck-holder .list-holder.the-deck .card.deck-joker {
    height: 54px;
    left: 54px;
  }
}
.menu .subtitle {
  margin-bottom: 10px;
  color: #17a2b8;
  font-weight: bold;
}
.menu .list-group {
  border: 1px solid #ccc;
}
.menu .audio-info {
  margin-top: 10px;
  margin-bottom: 10px;
}
.menu .table-bordered td,
.menu .table-bordered th {
  border-color: #7baed9;
}
.menu .table-bordered.room-info td,
.menu .table-bordered.room-info th {
  padding: 5px 10px;
}
.menu .table-bordered.room-info td:nth-child(1) {
  text-align: right;
}
.menu .table-bordered.room-info td:nth-child(2) {
  text-align: left;
}
.menu .table-bordered.room-info th {
  text-align: center;
  background-color: #7baed9;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  /* Rounded sliders */
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch input:checked + .slider {
  background-color: #2196F3;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}
#splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000;
  background-size: cover;
  background-position: bottom;
  background-image: url("./res/splash_portrait_1xxxhdpi.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
@media (orientation: landscape) and (min-height: 1401px) {
  #splash-screen {
    background-image: url("./res/splash_land_1xxxhdpi.png");
    background-size: cover;
    background-position: bottom;
  }
}
@media (orientation: landscape) and (max-height: 1400px) {
  #splash-screen {
    background-image: url("./res/splash_land_1xxhdpi.png");
    background-size: cover;
    background-position: bottom;
  }
}
@media (orientation: landscape) and (max-height: 1000px) {
  #splash-screen {
    background-image: url("./res/splash_land_1xhdpi.png");
    background-size: cover;
    background-position: bottom;
  }
}
@media (orientation: landscape) and (max-height: 800px) {
  #splash-screen {
    background-image: url("./res/splash_land_1mdpi.png");
    background-size: cover;
    background-position: bottom;
  }
}
@media (orientation: portrait) and (min-width: 1701px) {
  #splash-screen {
    background-image: url("./res/splash_portrait_1xxxhdpi.png");
    background-size: cover;
    background-position: bottom;
  }
}
@media (orientation: portrait) and (max-width: 1700px) {
  #splash-screen {
    background-image: url("./res/splash_portrait_1xxhdpi.png");
    background-size: cover;
    background-position: bottom;
  }
}
@media (orientation: portrait) and (max-width: 1000px) {
  #splash-screen {
    background-image: url("./res/splash_portrait_1xhdpi.png");
    background-size: cover;
    background-position: bottom;
  }
}
small {
  font-weight: inherit;
}
.emoji-list {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  min-height: 200px;
  max-height: calc(100vh - 250px);
}
.emoji-list .emoji {
  flex-grow: 1;
  text-align: center;
}
.emoji-list .emoji img {
  width: 60px;
}
@media (min-width: 990px) {
  .emoji-list .emoji img {
    width: 100px;
  }
}
.emoji-list .emoji:hover,
.emoji-list .emoji:active {
  box-shadow: inset 0px 0px 10px 0px #323232;
  cursor: pointer;
}
.emoji-list .emoji.text-template {
  background: #795548;
  color: #ffeb3b;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-weight: bold;
  min-width: 60px;
  border: 1px solid #593f35;
}
.emoji-list .emoji.text {
  background: #ffeb3b;
}
.emoji-list .emoji.text:after {
  content: "TEXT";
  font-weight: bold;
  padding-right: 10px;
}
.emoji-list .emoji.hand {
  background: #f44336;
}
.emoji-list .emoji.laugh {
  background: #2196f3;
}
.emoji-list .emoji.notty {
  background: #009688;
}
.emoji-list .emoji.unhappy {
  background: #ffeb3b;
}
.emoji-list .emoji.shock {
  background: #795548;
}
.emoji-list .emoji.person {
  background: #e91e63;
}
.emoji-list .emoji.organs {
  background: #8e1ee9;
}
.new-chat-noti:after {
  background: #f44336;
  border-radius: 100%;
  /* padding: 3px; */
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.well {
  height: 100%;
  width: 100%;
  text-align: center;
  opacity: 0.5;
}
.chat-box {
  overflow-y: auto;
  min-height: 200px;
  max-height: calc(100vh - 250px);
}
.chat-box .chat-message {
  background: #fff;
  color: #17a2b8;
  font-weight: bold;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
.chat-box .chat-message .player {
  color: hsla(199, 73%, 47%, 0.931);
}
.chat-box .chat-message .player:after {
  content: ": ";
}
.chat-box .chat-message .player.pos1 {
  color: hsla(2, 73%, 47%, 0.931);
}
.chat-box .chat-message .player.pos2 {
  color: hsla(130, 90%, 37%, 0.931);
}
.chat-box .chat-message .player.pos3 {
  color: #ff00bf;
}
.chat-box .chat-message .player.pos4 {
  color: #6200ff;
}
.chat-box .chat-message .player.pos5 {
  color: #ff7b00;
}
.chat-box .chat-message .message {
  color: #555;
}
.chat-input {
  width: 100%;
}
.chat-input .credits-left {
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.8;
}
.chat-popup-message {
  position: absolute;
  background: #fff;
  color: #17a2b8;
  font-weight: bold;
  padding: 10px;
  font-size: 16px;
  width: 120px;
  left: 10px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 2px 5px #646464;
  z-index: 1040;
  top: 60px;
}
.chat-popup-message:before {
  content: "";
  border-bottom: 15px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: -15px;
  right: 10px;
}
.chatpopup .nav-tabs {
  margin: 0;
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 5px;
}
.chatpopup .nav-tabs .nav-item {
  width: 50%;
  text-align: center;
  background: transparent;
  border-bottom: 5px solid #aaa;
  color: inherit;
  padding-top: 0;
  font-weight: bold;
}
.chatpopup .nav-tabs .nav-item.active {
  border-bottom: 5px solid #7baed9;
  color: #1c629c;
}
.all-card-transitions {
  -webkit-transition: box-shadow 300ms, margin-top 200ms;
  -moz-transition: box-shadow 300ms, margin-top 200ms;
  -o-transition: box-shadow 300ms, margin-top 200ms;
  transition: box-shadow 300ms, margin-top 200ms;
}
.price-list .box {
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  text-align: center;
}
.price-list .box .title {
  font-weight: bold;
}
.price-list .box .desc {
  font-size: 16px;
}
.price-list .box .price {
  margin-top: 10px;
  font-weight: bold;
}
.price-list .box.box1 {
  background: #ff9800;
  border: 4px solid #cc7a00;
}
.price-list .box.box1:active {
  background-color: #ffad33;
}
.price-list .box.box2 {
  background: #17a2b8;
  border: 4px solid #117a8b;
}
.price-list .box.box2:active {
  background-color: #1fc8e3;
}
.price-list .box.box3 {
  background: #17b81c;
  border: 4px solid #118b15;
}
.price-list .box.box3:active {
  background-color: #1fe325;
}
.price-list .box.box4 {
  background: #9C27B0;
  border: 4px solid #771e86;
}
.price-list .box.box4:active {
  background-color: #bc37d3;
}
.price-list .box.owned {
  opacity: 0.5;
}
.game-page {
  position: relative;
  /*
        level 1: >= 6
        level 2: >= 9
        level 3: >= 12
        level 4: >= 15
        level 5: >= 18
        
    */
}
.game-page .show-min-level5,
.game-page .show-min-level1,
.game-page .show-min-level2,
.game-page .show-min-level3 {
  display: none !important;
}
.game-page.level-1 .show-min-level1 {
  display: block !important;
}
.game-page.level-2 .show-min-level2 {
  display: block !important;
}
.game-page.level-2 .show-min-level1 {
  display: block !important;
}
.game-page.level-3 .show-min-level3 {
  display: block !important;
}
.game-page.level-3 .show-min-level1:not(.show-max-level2) {
  display: block !important;
}
.game-page.level-3 .show-min-level2 {
  display: block !important;
}
.game-page.level-4 .show-min-level4 {
  display: block !important;
}
.game-page.level-4 .show-min-level1:not(.show-max-level2) {
  display: block !important;
}
.game-page.level-4 .show-min-level2:not(.show-max-level2) {
  display: block !important;
}
.game-page.level-4 .show-min-level3 {
  display: block !important;
}
.game-page.level-5 .show-min-level5 {
  display: block !important;
}
.game-page.level-5 .show-min-level1:not(.show-max-level2) {
  display: block !important;
}
.game-page.level-5 .show-min-level2:not(.show-max-level2) {
  display: block !important;
}
.game-page.level-5 .show-min-level3 {
  display: block !important;
}
.game-page.level-5 .show-min-level4 {
  display: block !important;
}
.game-page.level-2 #last-updated-info {
  background: #ce0505;
}
.game-page.level-3 #last-updated-info {
  background: #ce0505;
}
.game-page.level-4 {
  filter: grayscale(40%);
}
.game-page.level-4 #last-updated-info {
  background: #ce0505;
}
.game-page.level-5 {
  filter: grayscale(80%);
}
.game-page.level-5 #last-updated-info {
  background: #ce0505;
}
.game-page .game-warning {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -40px;
  font-size: 35px;
  color: #fff;
  background: #0000007a;
  text-align: center;
  z-index: 100000;
  padding: 10px;
}
